import React from "react"
//import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Sidebar from "../components/sidebar"
const NewslettePage = ({ location }) => (
  <Layout>
    <SEO
      pageTitle="プライバシーポリシー"
      showSiteNameInTitle="true"
      pageDescription=""
      pagePath={location.pathname}
    />
    <div className="container flex-row">
      <div className="main privacy">
        <h1>プライバシーポリシー</h1>
        <h2>1. General part</h2>
        <h3>1.1 Information about the collection of personal data</h3>
        <p>
          (1) In the following, we will inform you in accordance with Regulation (EU) 2016/679 (General Data Protection Regulation "GDPR") about the collection of personal data when using our website (s). Personal data are all data that can be related to you personally, e.g. B. Name, address, email addresses, user behavior.
        </p>
        <p>
          (2) Responsible according to Art. 4 Para. 7 GDPR is Doitsu News Digest GmbH
          Registered in Germany<br />
          Company Number: HRB 52267<br />
          Registered Address: Immermannstr. 53, 40210 Düsseldorf, Germany.<br />
          You can reach our data protection officer at contact@voila-france.com.
        </p>
        <p>
          This policy only applies to our websites, systems and processes. If you leave our site via a link or otherwise, you will be subject to the policy of that website provider. We have no control over that policy or the terms of the website and you should check their policy before continuing to access the site.
        </p>
        <p>
          (3) We may collect and process the following data about you, you may supply this personally or we may collect this information from anyone acting on your behalf, such as agents or referees:
        </p>
        <p>
          Information that you provide by filling in our electronic forms. This includes information provided at the time of registering to use our site, subscribing to our service or requesting further services.
        </p>
        <p>
          Information that you provide by completing any paper application form or information request including copies of written or printed documents you supply to support your application.
        </p>
        <p>
          Information that you provide when you enter into prize draws or competitions which Eikoku News Digest Limited and Doitsu News Digest GmbH organise.
        </p>
        <p>
          Communication we have with you which you provide when you get in touch with us.
        </p>
        <p>
          Other information relevant to customer surveys and/or offers.
        </p>
        <p>
          Details of your visits to our website including, but not limited to, traffic data, location data, weblogs, operating system, browser usage, the resources that you access and other communication data.
        </p>
        <p>
          (4) The data that we collect from you will be primarily stored in our own secure data centres in the European Economic Area ("EEA"), it may also be shared between the group companies and staff as appropriate and as necessary to meet the needs of our business and to deliver you service.
        </p>
        <p>
          The data may also be transferred to, and stored at, destinations outside the EEA. It may also be processed by staff operating outside the EEA who work for us, one of our subsidiary companies or for one of our suppliers or agents.
        </p>
        <p>
          The data may also be used to meet our regulatory, legal, inspection or compliance responsibilities. By submitting your personal data, you agree to this transfer, storage or processing by Doitsu News Digest GmbH. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.
        </p>
        <p>
          We will use the personal information you have provided for:
        </p>
        <ul className="ul-w_80">
          <li>Providing services and goods to you or your companies.</li>
          <li>Internal record keeping e.g. to raise your invoice and to process your orders.</li>
          <li>In order to improve our products and services.</li>
          <li>Sending you communications by post, telephone or electronic means in order to inform you of other products, services or events related to Doitsu News Digest GmbH, which may include emails containing relevant news or competitions from our sponsors.</li>
          <li>Asking you to participate in surveys and market research.</li>
          <li>Transferring your data (name, email address, phone number or address) to third parties for them to communicate directly with you. But we will do so only where it is necessary, for example in order to send you a prize.</li>
        </ul>
        <p>
          (5) We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
        </p>
        <p>
          To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
        </p>
        <p>
          By law we have to keep basic information about our customers for six years after they cease being customers for tax purposes.
        </p>
        <p>
          (6) We may disclose your personal information to third parties:
        </p>
        <p>
          If appropriate we will also share your personal data with companies and organisations for the purposes of regulatory or inspection compliance, fraud protection and credit risk reduction.
        </p>
        <p>
          If we are under a duty to disclose or share your personal data in order to comply with any legal obligation to do so.
        </p>
        <p>
          We do not and will not sell, rent or trade your personal information. We will only disclose your personal information to third parties in the ways set out in this policy and, in particular, as set out below, and in accordance with your local data protection laws.
        </p>
        <p>
          We may disclose your personal information to overseas recipients. We will ensure that any such international transfers are either necessary for the performance of a contract between you and the overseas recipient or are made subject to appropriate or suitable safeguards as required by your local data protection laws.
        </p>
        <p>
          If you believe that any information we are holding on you is incorrect or incomplete, please write to or e-mail us as soon as possible, at the address written at the end of this policy.
        </p>
        <h3>1.2 Your rights</h3>
        <p>
          (1) You have the following rights towards us with regard to your personal data:
        </p>
        <p>
          - right to information,
        </p>
        <p>
          - right to correction or deletion,
        </p>
        <p>
          - right to restriction of processing,
        </p>
        <p>
          - right to object to processing,
        </p>
        <p>
          - Right to data portability,
        </p>
        <p>
          - Right to withdraw your consent.
        </p>
        <p>
          (2) You also have the right to complain to a data protection supervisory authority about the processing of your personal data by us.
        </p>
        <p>
          (3) We will inform you on request whether and if so which personal data we have stored about you. If we have stored incorrect information about you, we will correct it as soon as you point it out to us.
        </p>
        <h3>1.3.Other functions and offers on our website</h3>
        <p>
          (1) In addition to the purely informational use of our website, we offer various services that you can use if you are interested. To do this, you usually have to provide further personal data that we use to provide the respective service and for which the aforementioned data processing principles apply.
        </p>
        <p>
          (2) We sometimes use external service providers to process your data. These have been carefully selected and commissioned by us, are bound by our instructions and are regularly checked.
        </p>
        <p>
          (3) Furthermore, we can pass on your personal data to third parties if we offer campaign participation, competitions, contracts or similar services together with partners. You will receive more information on this when you provide your personal data or below in the description of the offer.
        </p>
        <p>
          (4) If our service providers or partners are based in a country outside the European Economic Area (EEA), we will inform you about the consequences of this in the description of the offer.
        </p>
        <h3>1.4 Objection or revocation against the processing of your data</h3>
        <p>
          (1) If you have given your consent to the processing of your data, you can revoke it at any time. Such a revocation affects the admissibility of processing your personal data after you have given it to us.
        </p>
        <p>
          (2) If we base the processing of your personal data on the weighing of interests, you can object to the processing. This is the case if the processing is in particular not necessary to fulfill a contract with you. When exercising such an objection, we ask you to explain the reasons why we should not process your personal data as we have done. In the event of your objection, we will examine the situation and will either stop or adjust the data processing or show you our compelling reasons worthy of protection, on the basis of which we will continue the processing.
        </p>
        <p>
          (3) Of course, you can object to the processing of your personal data for advertising and data analysis purposes at any time.
        </p>
        <h3>1.5</h3>
        <p>
          Changes to the privacy policy<br />
          We reserve the right to change this privacy policy by updating this page and you are advised to review this page from time to time to ensure you continue to be happy with the terms of this policy. Any changes will be effective immediately upon the posting of the revised privacy policy. However, Doitsu News Digest GmbH will not, without your agreement, use your personal information in a different manner materially than what was stated at the time it was collected.
        </p>
        <h3>1.6</h3>
        <p>

          Security<br />
          The websites <a href="https://newsdigest-group.com" target="_blank" rel="noopener noreferrer">https://newsdigest-group.com</a> and <a href="https://restaurants.news-digest.co.uk/" target="_blank" rel="noopener noreferrer">https://restaurants.news-digest.co.uk/</a> use SSL or TLS encryption for security reasons and for the protection of the transmission of confidential content, such as the inquiries you send to us as the site operator. You can recognize an encrypted connection in your browser's address line when it changes from "http://" to "https://" and the lock icon is displayed in your browser's address bar. If SSL or TLS encryption is activated, the data you transfer to us cannot be read by third parties.
        </p>
        <p>
          We are committed to ensuring that your information is as secure as possible. In order to prevent unauthorised access or disclosure, we have put in place physical, electronic and managerial procedures to safeguard and secure the information we collect online. However, no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
        </p>
        <h2>2. Special part</h2>
        <h3>2.1 Collection of personal data when using our website for information purposes</h3>
        <p>
          (1) If you only use the website for information purposes, i.e. if you do not register or otherwise provide us with information, we only collect your IP address as the personal data that your browser transmits to our server. The legal basis is Art. 6 para. 1 sentence 1 lit. f GDPR. If you want to view our website, we also collect technical data that is necessary for the website display. These can vary depending on the browser. For more information, please contact the respective browser provider.
        </p>
        <p>
          (2) In addition to the aforementioned data, cookies are stored on your computer when you use our website. Cookies are small text files that are stored on your hard drive and through which certain information flows to the place that sets the cookie. Cookies cannot run programs or transmit viruses to your computer. They serve to make the Internet offer more user-friendly and effective overall.
        </p>
        <p>
          (3) Use of cookies:
        </p>
        <p>
          In order to maintain quality of service for customers and provide a better user experience, the following websites collect certain information about visitors to its website using cookies:
          <a href="http://www.voila-france.com" target="_blank" rel="noopener noreferrer">http://www.voila-france.com</a>
        </p>
        <p>
          A cookie is a small piece of information sent by a web server to a web browser, which enables the server to collect information back from the browser and help us to provide him/her with the best possible experience.
        </p>
        <p>
          We use our cookies to help us:
        </p>
        <ul className="ul-w_80">
          <li>Make our website work as you'd expect.</li>
          <li>Remember your settings during and between visits.</li>
          <li>Improve the speed/security of the site.</li>
          <li>Allow you to share pages with social networking services like Facebook.</li>
          <li>Continuously improve our website for you.</li>
          <li>Make our marketing more efficient.</li>
          <li>Remember if we have already asked you certain questions (for example if you declined to take a survey).</li>
          <li>Collect any personally identifiable information (without your express permission).</li>
          <li>Pass data to advertising networks.</li>
          <li>Pass personally identifiable data to third parties.</li>
          <li>Twitter - Privacy Policy: <a href="https://twitter.com/en/privacy" target="_blank" rel="noopener noreferrer">htt</a>ps://twitter.com/en/privacy</li>
          <li>Facebook provides us with 'like' and 'send' buttons. Privacy Policy: <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">https://www.facebook.com/about/privacy/</a></li>
          <li>Google is used for Google Map integration and Google Analytics. To opt out of being tracked by Google Analytics across all websites visit <a href="https://tools.google.com/dlpage/gaoptout." target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout.</a></li>
          <li>Privacy Policy: <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">https://poli</a>cies.google.com/privacy</li>
          <li>YouTube powers some of the videos on our site.</li>
          <li>Privacy Policy: <a href="https://www.youtube.com/static?&template=privacy_guidelines" target="_blank" rel="noopener noreferrer">https:/</a>/www.youtube.com/static?&template=privacy_guidelines</li>
          <li>Instagram - Privacy Policy: <a href="https://help.instagram.com/155833707900388" target="_blank" rel="noopener noreferrer">https://help.instagram.com/155833707900388</a></li>
        </ul>
        <p>
          We do not associate the data which we gather from these sites with any personally identifying information from any other source. If we do want to collect personally identifiable data through our websites, we will tell you. We will make it clear when we collect personal data and will explain what we intend to do with it.
        </p>
        <p>
          You can learn more about all the cookies we use below.
        </p>
        <p>
          (4) Granting us permission to use cookies<br />
          If the settings on the software that you are using to view these websites (your browser) are adjusted to accept cookies we take this, and your continued use of our website, to mean that you are happy for us to use cookies.
        </p>
        <p>
          Should you wish to remove or not use cookies from our site you can learn how to do this below, however doing so will probably mean that our site will not work as you would expect, or at all.
        </p>
        <p>
          You will be asked again in no later than 365 days about your cookie preferences since the last time you were asked.
        </p>
        <p>
          (5) Turning cookies off<br />
          You can usually switch cookies off by adjusting your browser settings to stop it from accepting cookies. Doing so however will likely limit the functionality of our website and a large proportion of other websites around the world as cookies are a standard part of most modern websites.
        </p>
        <p>
          To learn how to change your setting of cookies, please visit <a href="https://www.aboutcookies.org" target="_blank" rel="noopener noreferrer">https://www.aboutcookies.org</a>.
        </p>
        <p>
          Please note that, if you adjust the cookie settings for our websites (listed above), this may prevent you from taking full advantage of our site afterwards.
        </p>
        <h3>2.3 Use of our portal / registration</h3>
        <p>
          (1) Parts of our online offer can only be used if you register by entering your email address, a password of your choice and your freely selectable user name. There is no compulsory real name, pseudonymous use is possible. We use the so-called double opt-in procedure for registration, i.e. your registration is only completed if you have previously confirmed your registration by clicking on the link contained in a confirmation email sent to you for this purpose. The provision of the above-mentioned data is mandatory; you can voluntarily provide all other information by using our portal.
        </p>
        <p>
          (2) If you have registered while using our online offer, your data can be made available to other portal participants in accordance with the contractual performance. Members who are not registered will not receive any information about you. Your user name and, if applicable, photo are visible to all registered members, regardless of whether you have approved them. In contrast, your entire profile with the data you have released is visible to all members who you have confirmed as personal contact. If you make content available to your personal contacts that you do not send by private message, this content is visible to third parties, provided your personal contact has given approval.
        </p>
        <h4>2.3.1. Competitions</h4>
        <p>
          (1) When participating in a competition published online on our website, the following personal data is collected (partly optional):
        </p>
        <p>
          Salutation, first name, last name, email address, telephone number, street, house number, post code, place of residence, date of birth
        </p>
        <p>
          (2) The data processing serves the purpose of carrying out the competition. The legal basis for the processing of personal data to fulfill this obligation is therefore § 28 Paragraph 1 Clause 1 No. 1 Federal Data Protection Act (BDSG) or (from May 25, 2018) Art. 6 Paragraph 1 lit. b) the EU General Data Protection Regulation (GDPR).
        </p>
        <p>
          (3) The personal data are stored on the external server of the Doitsu News Digest GmbH. We will send the email as part of the competition and the data will be made available to this system for this purpose.
        </p>
        <p>
          (4) The use of the data serves to determine the profit and fulfill the obligations arising from a profit. The data will also be used for interest-based advertising and mailings, provided that a corresponding opt-in has been issued.
        </p>
        <h3>2.4 Newsletter</h3>
        <p>
          (1) With your consent, you can subscribe to our newsletter, which we use to inform you about our current interesting offers. The advertised goods and services are named in the declaration of consent.
        </p>
        <p>
          (2) We use the so-called double opt-in procedure to register for our newsletter. This means that after you register, we will send you an email to the email address you provided, asking you to confirm that you want the newsletter to be sent. In addition, we save your IP addresses and the times of registration and confirmation. The purpose of the procedure is to prove your registration and, if necessary, to clarify a possible misuse of your personal data.
        </p>
        <p>
          (3) The only mandatory information for sending the newsletter is your email address. The provision of further, separately marked data is voluntary and is used to be able to address you personally. After your confirmation, we will save your email address for the purpose of sending the newsletter. The legal basis is Art. 6 para. 1 sentence 1 lit. a GDPR.
        </p>
        <p>
          (4) You can revoke your consent to the sending of the newsletter at any time and unsubscribe from the newsletter. You can declare your revocation by clicking on the link provided in each newsletter email, by emailing contact@voila-france.com or by sending a message to the contact details given in the legal notice.
        </p>
        <p>
          (5) We would like to point out that we evaluate your user behavior when sending the newsletter. For this evaluation, the emails sent contain so-called web beacons or tracking pixels, which represent one-pixel image files that are stored on our website. For the evaluations, we link the data and the web beacons with your email address and an individual ID.
        </p>
        <p>
          Links received in the newsletter also contain this ID.
        </p>
        <p>
          The data is only collected in a pseudonymized form, so the IDs are not linked to your other personal data, and it cannot be directly linked to a person.
        </p>
        <p>
          (6) We may also place advertisements from our advertising partners in our newsletters. This is not interest-based advertising from third-party networks, but only advertisements marketed directly by us. The legal basis is Art. 6 Para. 1 f) GDPR, whereby our legitimate interest is the economic operation of our online offer.
        </p>

        <h3>2.5 Web Analytics</h3>
        <h4>2.5.1 Use of Google Analytics</h4>
        <p>
          (1) This online offer uses Google Analytics, a web analytics service provided by Google Inc. ("Google"). Google Analytics uses so-called “cookies”, text files that are stored on your computer and that enable an analysis of your use of the website. The information generated by the cookie about your use of this website is usually transferred to a Google server in the USA and stored there. If IP anonymization is activated on this website, your IP address will be shortened beforehand by Google within member states of the European Union or in other contracting states of the Agreement on the European Economic Area. The full IP address will only be transmitted to a Google server in the USA and abbreviated there in exceptional cases.
        </p>
        <p>
          (2) The IP address transmitted by your browser as part of Google Analytics will not be merged with other Google data.
        </p>
        <p>
          (3) You can prevent Google Analytics from collecting your data on this website by clicking on the following link. An opt-out cookie is set which prevents the collection of your data on future visits to this website:
        </p>
        <p>
          Deactivate Google Analytics
        </p>
        <p>
          When deleting cookies in the browser, this opt-out must be repeated.
        </p>
        <p>
          (4) You can also prevent the storage of cookies by setting your browser software accordingly; however, we would like to point out that in this case you may not be able to use all functions of this online offer to their full extent. You can also prevent Google from collecting the data generated by the cookie and relating to your use of the website (including your IP address) and from processing this data by Google by using the browser plug-in available under the following link. Download and install in: <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout?hl=de</a>.
        </p>
        <p>
          (5) This website uses Google Analytics with the extension "_anonymizeIp ()". As a result, IP addresses are processed in abbreviated form, so that they cannot be linked to a person. As far as the data collected about you is personal, it is immediately excluded and the personal data is deleted immediately.
        </p>
        <p>
          (6) We use Google Analytics to analyze and regularly improve the use of our website. We can use the statistics obtained to improve our offer and make it more interesting for you as a user. For the exceptional cases in which personal data are transferred to the USA, Google has submitted to the EU-US Privacy Shield, <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>. The legal basis for the use of Google Analytics is Art. 6 Para. 1 S. 1 lit. f GDPR.
        </p>
        <p>
          (7) Third-party information: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin 4, Ireland, Fax: +353 (1) 436 1001. Terms of use: <a href="http://www.google.com/analytics/terms/de.html" target="_blank" rel="noopener noreferrer">http://www.google.com/analytics/terms/de.html</a> , overview of data protection: <a href="http://www.google.com/intl/de/analytics/learn/privacy.html" target="_blank" rel="noopener noreferrer">http://www.google.com/intl/de/analytics/learn/privacy.html</a> , as well as the data protection declaration : <a href="http://www.google.de/intl/de/policies/privacy" target="_blank" rel="noopener noreferrer">http://www.google.de/intl/de/policies/privacy</a>.
        </p>
        <p>
          (8) This website also uses Google Analytics for a cross-device analysis of visitor flows, which is carried out via a user ID. You can deactivate the cross-device analysis of your usage in your customer account under "My data", "Personal data".
        </p>
        <h4>2.5.2 Use of the Google AMP Client ID API</h4>
        <p>
          (1) This online offer uses the Google AMP, a client function of Google Inc. ("Google"). This function is required to link user activities on the accelerated mobile pages provided by Google, which are also hosted by Google, and pages that are not shown in AMP format in Google Analytics.
        </p>
        <h4>2.5.3 Use of the Google Tag Manager</h4>
        <p>
          (1) This online offer uses the Google Tag Manager, a code management function of Google Inc. ("Google"). This function is required to unify tags.
        </p>

        <h3>2.6 Social media</h3>
        <h4>2.6.1 Use of social media plug-ins</h4>
        <p>
          (1) We currently use the following social media plug-ins: Facebook, Google+, Twitter. We use the so-called two-click solution. This means that when you visit our website, no personal data is initially passed on to the providers of the plug-ins. You can recognize the provider of the plug-in by the marking on the box above its initial letter or the logo. We give you the opportunity to communicate directly with the provider of the plug-in using the button. The plug-in provider only receives the information that you have called up the corresponding website of our online offer if you click on the marked field and thereby activate it. In the case of Facebook and New Work SE, according to the respective provider in Germany, the IP address is anonymized immediately after collection. By activating the plug-in, personal data is transmitted from you to the respective plug-in provider and stored there (for US providers in the USA).
        </p>
        <p>
          (2) We have no influence on the data collected and data processing operations, nor are we aware of the full extent of the data collection, the purposes of the processing or the storage periods. We also have no information on the deletion of the data collected by the plug-in provider.
        </p>
        <p>
          (3) The plug-in provider stores the data collected about you as usage profiles and uses them for the purposes of advertising, market research and / or to design its website in line with requirements. Such an evaluation is carried out in particular (also for users who are not logged in) to display needs-based advertising and to inform other users of the social network about your activities on our website. You have a right to object to the creation of these user profiles, whereby you must contact the respective plug-in provider to exercise them. Via the plug-ins, we offer you the opportunity to interact with social networks and other users, so that we can improve our offer and make it more interesting for you as a user. The legal basis for using the plug-ins is Art. 6 Para. 1 S. 1 lit. f GDPR.
        </p>
        <p>
          (4) The data is passed on regardless of whether you have an account with the plug-in provider and are logged in there. If you are logged in with the plug-in provider, your data collected from us will be assigned directly to your existing account with the plug-in provider. If you press the activated button and e.g. For example, if the page links, the plug-in provider also stores this information in your user account and shares it publicly with your contacts. We recommend that you log out regularly after using a social network, but especially before activating the button, as this way you can avoid being assigned to your profile by the plug-in provider.
        </p>
        <p>
          (5) Further information on the purpose and scope of the data collection and its processing by the plug-in provider can be found in the data protection declarations of these providers given below. There you will also find further information about your rights and setting options to protect your privacy.
        </p>
        <p>
          (6) Addresses of the respective plug-in providers and URL with their data protection information:
        </p>
        <p>
          a) Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbor, Dublin 2, Ireland; data processing is based on an agreement on the joint processing of personal data in accordance with Art. 26 GDPR: <a href="https://www.facebook.com/legal/terms/page_controller_addendum" target="_blank" rel="noopener noreferrer">https://www.facebook.com/legal/terms/page_controller_addendum</a>. Data protection declaration: <a href="https://www.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">https://www.facebook.com/about/privacy/</a> ; Opt-out: <a href="https://www.facebook.com/settings?tab=ads" target="_blank" rel="noopener noreferrer">https://www.facebook.com/settings?tab=ads</a>. Facebook has submitted to the EU-US Privacy Shield, <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.
        </p>
        <p>
          b) Google Inc., 1600 Amphitheater Parkway, Mountainview, California 94043, USA; <a href="https://www.google.com/policies/privacy/partners/?hl=de" target="_blank" rel="noopener noreferrer">https://www.google.com/policies/privacy/partners/?hl=de</a>. Google has submitted to the EU-US Privacy Shield, <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.
        </p>
        <p>
          c) Twitter, Inc., 1355 Market St, Suite 900, San Francisco, California 94103, USA; <a href="https://twitter.com/privacy" target="_blank" rel="noopener noreferrer">https://twitter.com/privacy</a>. Twitter has submitted to the EU-US Privacy Shield, <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.
        </p>
        <p>
          d) New Work SE, Dammtorstrasse 30, 20354 Hamburg, DE; <a href="http://www.xing.com/privacy" target="_blank" rel="noopener noreferrer">http://www.xing.com/privacy</a>.
        </p>
        <h4>2.6.3 Integration of Facebook content</h4>
        <p>
          (1) The controller has integrated components of the Facebook company on this website. Facebook is a social network. A social network is a social meeting point operated on the Internet, an online community that usually enables users to communicate with each other and to interact in virtual space. A social network can serve as a platform for exchanging opinions and experiences or enables the internet community to provide personal or company-related information. Facebook enables the users of the social network, among other things, to create private profiles, upload photos and network via friendship requests.
        </p>
        <p>
          (2) Facebook's operating company is Facebook, Inc., 1 Hacker Way, Menlo Park, CA 94025, USA. The person responsible for the processing of personal data, if a data subject lives outside the USA or Canada, is Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbor, Dublin 2, Ireland.
        </p>
        <p>
          (3) Each time one of the individual pages of this website is accessed, which is operated by the controller and on which a Facebook component (Facebook plug-in) has been integrated, the Internet browser on the information technology system of the data subject is automatically activated the respective Facebook component causes a representation of the corresponding Facebook component to be downloaded from Facebook. A complete overview of all Facebook plug-ins can be found at <a href="https://developers.facebook.com/docs/plugins/?locale=de_DE" target="_blank" rel="noopener noreferrer">https://developers.facebook.com/docs/plugins/?locale=de_DE</a>. As part of this technical process, Facebook receives knowledge of which specific subpage of our website is visited by the data subject.
        </p>
        <p>
          (4) If the data subject is logged in to Facebook at the same time, Facebook recognizes each time our website is accessed by the data subject and for the entire duration of their stay on our website, which specific subpage of our website the data subject visits. This information is collected by the Facebook component and assigned to the respective Facebook account of the person concerned by Facebook. If the person concerned presses one of the Facebook buttons integrated on our website, for example the "Like" button, or if the person concerned makes a comment, Facebook assigns this information to the person's personal Facebook user account and stores this personal data .
        </p>
        <p>
          (5) Facebook always receives information via the Facebook component that the data subject has visited our website if the data subject is logged in to Facebook at the same time as accessing our website; this takes place regardless of whether the person concerned clicks on the Facebook component or not. If the data subject does not want this information to be transmitted to Facebook, he or she can prevent the transmission by logging out of their Facebook account before accessing our website.
        </p>
        <p>
          (6) The data policy published by Facebook , which is available at <a href="https://de-de.facebook.com/about/privacy/" target="_blank" rel="noopener noreferrer">https://de-de.facebook.com/about/privacy/</a>, provides information about the collection, processing and use of personal data by Facebook. It also explains which setting options Facebook offers to protect the privacy of the data subject. In addition, various applications are available that make it possible to suppress data transmission to Facebook. Such applications can be used by the data subject to suppress data transmission to Facebook.
        </p>
        <h4>2.6.4 Integration of Instagram content</h4>
        <p>
          (1) The controller has integrated components of the Instagram service on this website. Instagram is a service that qualifies as an audiovisual platform and enables users to share photos and videos and also to disseminate such data on other social networks.
        </p>
        <p>
          Instagram is operated by Instagram LLC, 1 Hacker Way, Building 14 First Floor, Menlo Park, CA, USA.
        </p>
        <p>
          (2) Each time one of the individual pages of this website is accessed, which is operated by the controller and on which an Instagram component (Insta button) has been integrated, the Internet browser on the information technology system of the data subject is automatically activated by the respective person Instagram component causes a representation of the corresponding component to be downloaded from Instagram. As part of this technical process, Instagram receives knowledge of which specific subpage of our website is visited by the data subject.
        </p>
        <p>
          (3) If the data subject is logged in to Instagram at the same time, Instagram recognizes each time our website is accessed by the data subject and for the entire duration of their stay on our website, which specific subpage the data subject visits. This information is collected by the Instagram component and assigned to the respective Instagram account of the data subject by Instagram. If the data subject clicks one of the Instagram buttons integrated on our website, the data and information transmitted with it are assigned to the personal Instagram user account of the data subject and saved and processed by Instagram.
        </p>
        <p>
          (4) Instagram always receives information via the Instagram component that the person concerned has visited our website if the person concerned is logged into Instagram at the same time as accessing our website; this takes place regardless of whether the person concerned clicks on the Instagram component or not. If the data subject does not want this information to be transmitted to Instagram, they can prevent the transmission by logging out of their Instagram account before visiting our website.
        </p>
        <p>
          (5) Further information and the applicable data protection regulations of Instagram can be found at <a href="https://help.instagram.com/155833707900388" target="_blank" rel="noopener noreferrer">https://help.instagram.com/155833707900388</a> and <a href="https://www.instagram.com/about/legal/privacy/" target="_blank" rel="noopener noreferrer">https://www.instagram.com/about/legal/privacy/</a>.
        </p>
        <h4>2.6.6 Integration of Twitter content</h4>
        <p>
          (1) The controller has integrated Twitter components on this website. Twitter is a multilingual, publicly accessible microblogging service on which users can publish and distribute so-called tweets, i.e. short messages that are limited to 140 characters. These short messages are available to everyone, including anyone who is not logged in to Twitter. The tweets are also displayed to the so-called followers of the respective user. Followers are other Twitter users who follow a user's tweets. Twitter also enables a wide audience to be addressed via hashtags, links or retweets.
        </p>
        <p>
          (2) The operating company of Twitter is Twitter, Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA.
        </p>
        <p>
          (3) Each time one of the individual pages of this website is accessed, which is operated by the controller and on which a Twitter component (Twitter button) has been integrated, the Internet browser on the information technology system of the data subject is automatically activated by the respective person Twitter component causes a representation of the corresponding Twitter component to be downloaded from Twitter. Further information on the Twitter buttons can be found at <a href="https://about.twitter.com/de/resources/buttonsavailable" target="_blank" rel="noopener noreferrer">https://about.twitter.com/de/resources/buttonsavailable</a>. As part of this technical process, Twitter receives knowledge of which specific subpage of our website is visited by the data subject. The purpose of integrating the Twitter component is to enable our users to disseminate the content of this website, to make this website known in the digital world and to increase our visitor numbers.
        </p>
        <p>
          (4) If the data subject is logged in to Twitter at the same time, Twitter recognizes each time our website is accessed by the data subject and for the entire duration of their stay on our website, which specific subpage of our website the data subject visits. This information is collected by the Twitter component and assigned to the respective Twitter account of the data subject by Twitter. If the data subject clicks one of the Twitter buttons integrated on our website, the data and information transmitted with it are assigned to the personal Twitter user account of the data subject and saved and processed by Twitter.
        </p>
        <p>
          (5) Twitter always receives information via the Twitter component that the data subject has visited our website if the data subject is logged in to Twitter at the same time as accessing our website; this takes place regardless of whether the person concerned clicks on the Twitter component or not. If the data subject does not want this information to be transmitted to Twitter, the person can prevent the transmission by logging out of their Twitter account before accessing our website.
        </p>
        <p>
          (6) The applicable data protection regulations of Twitter are available at <a href="https://twitter.com/privacy?lang=de" target="_blank" rel="noopener noreferrer">https://twitter.com/privacy?lang=de</a>.
        </p>

        <h4>2.6.8 Integration of Google Maps</h4>
        <p>
          (1) We use Google Maps on our online offers. This enables us to show you interactive maps directly on the website and enables you to use the map function conveniently.
        </p>
        <p>
          (2) When you visit our online offers, Google receives the information that you have accessed the corresponding subpage of our online offers. This takes place regardless of whether Google provides a user account that you are logged in to or whether there is no user account. If you are logged in to Google, your data will be assigned directly to your account. If you do not want your profile to be assigned to Google, you must log out before activating the button. Google stores your data as usage profiles and uses them for the purposes of advertising, market research and / or the needs-based design of its website. Such an evaluation is carried out in particular (even for users who are not logged in) to provide needs-based advertising and to inform other users of the social network about your activities on our website. You have a right to object to the creation of these user profiles, and you must contact Google to exercise them.
        </p>
        <p>
          (3) Further information on the purpose and scope of data collection and its processing by the plug-in provider can be found in the privacy policy of the provider. There you will also find further information about your rights and setting options to protect your privacy: <a href="http://www.google.de/intl/de/policies/privacy" target="_blank" rel="noopener noreferrer">http://www.google.de/intl/de/policies/privacy</a>. Google also processes your personal data in the USA and has submitted to the EU-US Privacy Shield, <a href="https://www.privacyshield.gov/EU-US-Framework" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/EU-US-Framework</a>.
        </p>

        <h2>3. Use-based online advertising</h2>
        <p>
          We work with the following providers of ad services:
        </p>
        <p>
          ● Google Ireland Limited (google.de), Gordon House, Barrow St Dublin 4, Ireland, ("Google") provides an ad server as a processor. If you do not want Google to continue collecting data, please click on the following link . This opt-out cookie deletes the information previously stored and prevents further collection of information. You can prevent participation in the Google tracking process by setting the ad settings accordingly. These settings are deleted when you delete your cookies.
        </p>
        <h5>a. Google AdSense</h5>
        <p>
          (1) This website uses the online advertising service Google AdSense, which can be used to present advertising tailored to your interests. We are interested in showing you advertisements that may be of interest to you in order to make our website more interesting for you. For this purpose, statistical information about you is collected, which is processed by our advertising partners. These advertisements can be recognized by the "Google ads" notice in the respective advertisement.
        </p>
        <p>
          (2) When you visit our website, Google receives the information that you have accessed our website. Google uses a web beacon to set a cookie on your computer. We have no influence on the data collected, nor are we aware of the full extent of the data collection and the storage period. Your data will be transferred to the USA and evaluated there. If you are logged in with your Google account, your data can be assigned directly to it. If you do not want your Google profile to be assigned, you must log out. It is possible that this data will be passed on to Google's contractual partners, third parties and authorities. The legal basis for the processing of your data is Art. 6 Para. 1 S. 1 lit. f GDPR.
        </p>
        <p>
          This website has also activated third-party Google AdSense ads. The aforementioned data can be transferred to these third-party providers.
        </p>
        <p>
          (3) You can prevent the installation of Google AdSense cookies in various ways: a) by setting your browser software accordingly, in particular the suppression of third-party cookies means that you will not receive any advertisements from third-party providers; b) by deactivating the cookies for conversion tracking, by setting your browser so that cookies from the domain "www.googleadservices.com" are blocked ( here ), this setting being deleted when you delete your cookies; c) by deactivating the interest-based ads of the providers that are part of the self-regulation campaign "About Ads" via this link, this setting will be deleted if you delete your cookies; d) by permanent deactivation in your browsers Firefox, Internet Explorer or Google Chrome under this link . We would like to point out that in this case you may not be able to use all functions of this offer to their full extent.
        </p>
        <p>
          (4) For more information on the purpose and scope of data collection and processing, as well as further information on your rights and setting options to protect your privacy, please contact: Google Inc., 1600 Amphitheater Parkway, Mountainview, California 94043, USA; Privacy policy for advertising: see here . Google has submitted to the EU-US Privacy Shield .
        </p>
        <h5>b. Google AdWords conversion</h5>
        <p>
          (1) We use the offer of Google Adwords to draw attention to our attractive offers on external websites with the help of advertising materials (so-called Google Adwords). In relation to the data from the advertising campaigns, we can determine how successful the individual advertising measures are. We are therefore interested in showing you advertising that is of interest to you, to make our website more interesting for you and to achieve a fair calculation of advertising costs.
        </p>
        <p>
          (2) These advertising materials are delivered by Google via so-called "ad servers". For this purpose, we use ad server cookies, through which certain parameters for measuring success, such as the insertion of ads or clicks by users, can be measured. If you access our website via a Google ad, Google Adwords stores a cookie on your PC. These cookies usually lose their validity after 30 days and should not be used to identify you personally. For this cookie, the unique cookie ID, number of ad impressions per placement (frequency), last impression (relevant for post-view conversions) and opt-out information (marking that the user does not wants to be addressed more).
        </p>
        <p>
          (3) These cookies enable Google to recognize your internet browser. If a user visits certain pages of an Adwords customer's website and the cookie stored on their computer has not yet expired, Google and the customer can recognize that the user clicked on the ad and was redirected to this page. A different cookie is assigned to each Adwords customer. Cookies cannot therefore be tracked via the websites of Adwords customers. We ourselves do not collect and process any personal data in the advertising measures mentioned. We only receive statistical evaluations from Google. Based on these evaluations, we can see which of the advertising measures used are particularly effective.
        </p>
        <p>
          (4) Due to the marketing tools used, your browser automatically establishes a direct connection to the Google server. We have no influence on the scope and further use of the data collected by Google through the use of this tool and therefore inform you according to our level of knowledge: By integrating AdWords Conversion, Google receives the information that you are the corresponding part of our website called up or clicked on one of our ads. If you are registered with a Google service, Google can assign the visit to your account. Even if you are not registered with Google or have not logged in, there is a possibility that the provider will find out and save your IP address.
        </p>
        <p>
          (5) You can prevent participation in this tracking process in various ways: a) by setting your browser software accordingly, in particular the suppression of third-party cookies means that you will not receive any advertisements from third-party providers; b) by deactivating the cookies for conversion tracking, by setting your browser so that cookies from the domain "www.googleadservices.com" are blocked ( here ), this setting being deleted when you delete your cookies; c) by deactivating the interest-based ads of the providers that are part of the self-regulation campaign "About Ads" via this link : <a href="http://optout.aboutads.info/?c=2" target="_blank" rel="noopener noreferrer">http://optout.aboutads.info/?c=2</a>, this setting being deleted when you delete your cookies; d) by permanent deactivation in your browsers Firefox, Internet Explorer or Google Chrome under this link: <a href="https://support.google.com/ads/answer/7395996" target="_blank" rel="noopener noreferrer">https://support.google.com/ads/answer/7395996</a>. We would like to point out that in this case you may not be able to use all functions of this offer to their full extent.
        </p>
        <p>
          (6) The legal basis for processing your data is Art. 6 Para. 1 S. 1 lit. f GDPR. Further information on DoubleClick by Google can be found here and here: <a href="https://support.google.com/adsense/answer/2839090" target="_blank" rel="noopener noreferrer">https://support.google.com/adsense/answer/2839090</a>, as well as data protection at Google in general here . Alternatively, you can visit the website of the Network Advertising Initiative (NAI) .
        </p>
        <h5>c. Google remarketing</h5>
        <p>
          In addition to Adwords Conversion, we use the Google Remarketing application. This is a procedure with which we would like to address you again. This application enables you to see our advertisements when you continue to use the Internet after visiting our website. This is done by means of cookies stored in your browser, via which your usage behavior when visiting different websites is recorded and evaluated by Google. This allows Google to determine your previous visit to our website. According to its own statements, Google does not combine the data collected as part of the remarketing with your personal data, which may be stored by Google. According to Google, pseudonymization is used in remarketing in particular.
        </p>
        <h5>d. Amazon affiliate program</h5>
        <p>
          (1) We work with Amazon Japan and are a partner of the advertising program, which was designed to provide a medium for websites, by means of which advertising placements and links to amazon.jp can be used to earn reimbursement of advertising costs. With the program, we aim to provide you with advertisements that are of interest to you and that our website becomes more interesting for our users.
        </p>
        <p>
          (2) For the provision of the advertisements, statistical information about you is collected, which is processed by our advertising partners. When you visit the website, Amazon receives the information that you have accessed the corresponding page on our website. Amazon uses web beacons to determine your needs and, if necessary, sets a cookie on your computer. We have no influence on the data collected, nor are we aware of the full extent of the data collection and the storage period. If you are logged into Amazon, your data can be assigned directly to your account there. If you do not want your Amazon profile to be assigned, you must log out. It is possible that your data will be passed on to contract partners from Amazon and the authorities. We have no influence on the data collected, we still know the full extent of the data collection. The data is transferred to the USA and evaluated there. The legal basis for the processing of your data is Art. 6 Para. 1 S. 1 lit. f GDPR.
        </p>
        <p>
          (3) You can prevent the installation of the cookies of the Amazon partner program in different ways: a) by setting your browser software accordingly, in particular the suppression of third-party cookies means that you will not receive any advertisements from third-party providers; b) by deactivating interest-based advertisements on Amazon via this link ; c) by deactivating the interest-based ads of the providers who are part of the self-regulation campaign "About Ads" via this link , whereby this setting will be deleted if you delete your cookies. We would like to point out that in this case you may not be able to use all functions of this offer to their full extent.
        </p>
        <p>
          (4) For more information on the purpose and scope of data collection and processing, as well as further information on your rights and setting options to protect your privacy, you can also get from the above data protection declaration at: Amazon EU S.à.rl, Amazon Services Europe S.à.rl and Amazon Media EU S.à.rl, all three located at 5, Rue Plaetis, L – 2338 Luxembourg; Email: ad-feedback@amazon.de. Amazon has submitted to the EU-US Privacy Shield . Further information on data use by Amazon can be found in the company's data protection declaration.
        </p>
        <h5>e. A8</h5>
        <p>
          (1) We work with A8 and are a partner of the advertising program, which was designed to provide a medium for websites, by means of which advertising placements and links to A8.net can be used to earn reimbursement of advertising costs. With the program, we aim to provide you with advertisements that are of interest to you and that our website becomes more interesting for our users.
        </p>
        <p>
          (2) For the provision of the advertisements, statistical information about you is collected, which is processed by our advertising partners. When you visit the website, A8 receives the information that you have accessed the corresponding page on our website. A8 uses web beacons to determine your needs and, if necessary, sets a cookie on your computer. We have no influence on the data collected, nor are we aware of the full extent of the data collection and the storage period. If you are logged into A8, your data can be assigned directly to your account there. If you do not want your A8 profile to be assigned, you must log out. It is possible that your data will be passed on to contract partners from A8 and the authorities. We have no influence on the data collected, we still know the full extent of the data collection. The data is transferred to Japan and evaluated there. The legal basis for the processing of your data is Art. 6 Para. 1 S. 1 lit. f GDPR.
        </p>
        <p>
          (3) You can prevent the installation of the cookies of the A8 partner program in different ways: a) by setting your browser software accordingly, in particular the suppression of third-party cookies means that you will not receive any advertisements from third-party providers; b) by contacting the service provider.
        </p>
        <p>
          (4) For more information on the purpose and scope of data collection and processing, as well as further information on your rights and setting options to protect your privacy, you can also get from the above data protection declaration at FAN Communications, Inc., located at Aoyama Diamond Building 2F, Shibuya 1-1-8, Shibuya-ku, Tokyo, Japan; Phone: +81 03-5766-3780. Further information on data use by A8 can be found in the company's data protection declaration.
        </p>

        <h3>3.4 Other service providers used</h3>
        <p>
          ●Netlify<br />
            We use Netlify for hosting and deploying some of our webpages. <br />
            For more information on the product and the data protection declaration by Netlify can be found at: Netlify, Inc., located at 2325 3rd Street, Suite 215, San Francisco, California 94107. You may contact by sending correspondence to the foregoing address or by emailing them at support@netlify.com.
        </p>
      </div>
      <Sidebar />
    </div>
  </Layout>
)
export default NewslettePage
